import { GET_CATEGORY_SUCCESS, LOAD_CATEGORIES_SUCCESS } from "../actions/categoryActions";

// Initial state
const initialState = {
    categories: null,
    category: null,
};

// Reducer function
export const category = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
            };
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
            };
        default:
            return state;
    }
};
