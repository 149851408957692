import React, { useCallback, useState } from 'react';
import { TextField, Button, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { selectedImageStyle, noImageDivStyle, VisuallyHiddenInput, formStyle } from './styles';


const PhotoEditForm = ({ handleSubmit, photo, galleries, handlePhotoChange, photoSrc, handleImageChange }) => {
    const [changed, setChanged] = useState(false);

    const handleImageChangeInternal = useCallback((e) => {
        const file = e.target.files[0]
        if (!file) {
            return;
        }

        const fileURL = URL.createObjectURL(file);
        handleImageChange({ file, fileURL });
        setChanged(true);
    }, [handleImageChange]);

    const handlePhotoNameChange = useCallback((e) => {
        handlePhotoChange({ ...photo, photoName: e.target.value });
        setChanged(true);
    }, [photo, handlePhotoChange]);

    const handleGalleryChangeInternal = useCallback((e) => {
        handlePhotoChange({ ...photo, galleryId: e.target.value });
        setChanged(true);
    }, [photo, handlePhotoChange]);

    const handleSlideshowChangeInternal = useCallback((e) => {
        handlePhotoChange({ ...photo, includeInSlideshow: e.target.checked });
        setChanged(true);
    }, [photo, handlePhotoChange]);

    const handlePanoramaChangeInternal = useCallback((e) => {
        handlePhotoChange({ ...photo, isPanorama: e.target.checked });
        setChanged(true);
    }, [photo, handlePhotoChange]);

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <TextField
                label="Photo Name"
                value={photo?.photoName || ""}
                margin="normal"
                onChange={handlePhotoNameChange}
            />
            <Select
                label="Gallery"
                value={photo?.galleryId || ''}
                onChange={handleGalleryChangeInternal}
                sx={{ minWidth: 600 }}
            >
                {(galleries ?? []).map((gallery) => (
                    <MenuItem key={gallery.id} value={gallery.id}>{gallery.categoryName} - {gallery.galleryName}</MenuItem>
                ))}
            </Select>
            {!!photoSrc ? <img src={photoSrc} style={selectedImageStyle} alt='Photo' /> : <div style={noImageDivStyle}>Choose photo</div>}
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} margin="dense">
                Cover Image
                <VisuallyHiddenInput type="file" onChange={handleImageChangeInternal} />
            </Button>
            <FormControlLabel control={<Checkbox onChange={handleSlideshowChangeInternal} value={!!photo?.includeInSlideshow} />} label="Include in slideshow" />
            <FormControlLabel control={<Checkbox onChange={handlePanoramaChangeInternal} value={!!photo?.isPanorama} />} label="Panorama" />
            <Button type="submit" variant="contained" onClick={handleSubmit} disabled={!changed || !photoSrc || !photo?.photoName || !photo?.galleryId} style={{ alignSelf: 'center' }}>Save</Button>
        </form >
    );
};

export default PhotoEditForm;
