import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, Outlet } from 'react-router-dom';
import LoginPage from './LoginPage';
import { Paper, Drawer, List, ListItem, ListItemText } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import GalleryIcon from '@mui/icons-material/PhotoLibrary';
import PhotoIcon from '@mui/icons-material/Photo';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

const drawerWidth = 240;

function AdminPage() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const location = useLocation();

    if (!isAuthenticated) {
        return <LoginPage />;
    }

    const isPathSelected = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <Paper sx={{ display: 'flex', height: '100%', position: 'fixed', minWidth: '100%' }}>
            <Drawer variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <List>
                    <ListItem button component={Link} to="/admin/categories" selected={isPathSelected('/admin/categories')}>
                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                            <ListItemIcon>
                                <CategoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Categories" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem button component={Link} to="/admin/galleries" selected={isPathSelected('/admin/galleries')}>
                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                            <ListItemIcon>
                                <GalleryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Galleries" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem button component={Link} to="/admin/photos" selected={isPathSelected('/admin/photos')}>
                        <ListItemButton sx={{ m: -1, ml: -2, mr: -2 }}>
                            <ListItemIcon>
                                <PhotoIcon />
                            </ListItemIcon>
                            <ListItemText primary="Photos" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Paper sx={{ flexGrow: 1, alignSelf: 'stretch', p: 2, overflowY: 'scroll' }}>
                <Outlet />
            </Paper>
        </Paper>
    );
}

export default AdminPage;
