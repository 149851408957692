import './AboutPage.css';
import React from 'react';
import Paper from '@mui/material/Paper';

function AboutPage() {
    return (
        <Paper>
            About Page
        </Paper>
    );
}

export default AboutPage;
