import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { generateKey } from "../common/utils/keyUtils";
import { getFileExtension } from "../common/utils/fileUtils";
import { getFirestore, collection, setDoc, getDocs, deleteDoc, doc, getDoc, query, where } from "firebase/firestore";

// Action types
export const CREATE_PHOTO_START = 'CREATE_PHOTO_START';
export const CREATE_PHOTO_SUCCESS = 'CREATE_PHOTO_SUCCESS';
export const CREATE_PHOTO_ERROR = 'CREATE_PHOTO_ERROR';
export const LOAD_PHOTOS_START = 'LOAD_PHOTOS_START';
export const LOAD_PHOTOS_SUCCESS = 'LOAD_PHOTOS_SUCCESS';
export const LOAD_PHOTOS_ERROR = 'LOAD_PHOTOS_ERROR';
export const DELETE_PHOTO_START = 'DELETE_PHOTO_START';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_ERROR = 'DELETE_PHOTO_ERROR';
export const GET_PHOTO_START = 'GET_PHOTO_START';
export const GET_PHOTO_SUCCESS = 'GET_PHOTO_SUCCESS';
export const GET_PHOTO_ERROR = 'GET_PHOTO_ERROR';
export const UPDATE_PHOTO_START = 'UPDATE_PHOTO_START';
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS';
export const UPDATE_PHOTO_ERROR = 'UPDATE_PHOTO_ERROR';
export const LOAD_PHOTOS_BY_GALLERY_START = 'LOAD_PHOTOS_BY_GALLERY_START';
export const LOAD_PHOTOS_BY_GALLERY_SUCCESS = 'LOAD_PHOTOS_BY_GALLERY_SUCCESS';
export const LOAD_PHOTOS_BY_GALLERY_ERROR = 'LOAD_PHOTOS_BY_GALLERY_ERROR';
export const LOAD_SLIDESHOW_START = 'LOAD_SLIDESHOW_START';
export const LOAD_SLIDESHOW_SUCCESS = 'LOAD_SLIDESHOW_SUCCESS';
export const LOAD_SLIDESHOW_ERROR = 'LOAD_SLIDESHOW_ERROR';

// Create photo actions
export const createPhotoStart = () => {
    return { type: CREATE_PHOTO_START };
};

export const createPhotoSuccess = () => {
    return { type: CREATE_PHOTO_SUCCESS };
};

export const createPhotoError = (errorMessage) => {
    return { type: CREATE_PHOTO_ERROR, payload: errorMessage };
};

export const createPhoto = (photo, image, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createPhotoStart());
            const storage = getStorage();
            const id = generateKey();
            const storagePath = `photos/${id}.${getFileExtension(image.name)}`;
            const imageRef = ref(storage, storagePath);
            await uploadBytes(imageRef, image);

            const photoData = {
                ...photo,
                path: storagePath,
                id,
            };

            const firestore = getFirestore();
            await setDoc(doc(firestore, "photos", id), photoData);
            dispatch(createPhotoSuccess());
            navigate('/admin/photos?galleryId=' + photo.galleryId);
        } catch (error) {
            dispatch(createPhotoError(error.message));
        }
    };
};

// Delete photo actions
export const deletePhotoStart = () => {
    return { type: DELETE_PHOTO_START };
};

export const deletePhotoSuccess = () => {
    return { type: DELETE_PHOTO_SUCCESS };
};

export const deletePhotoError = (errorMessage) => {
    return { type: DELETE_PHOTO_ERROR, payload: errorMessage };
};

export const deletePhoto = (id, galleryId) => {
    return async (dispatch) => {
        try {
            dispatch(deletePhotoStart());
            const firestore = getFirestore();
            const photoRef = doc(firestore, "photos", id);
            await deleteDoc(photoRef);
            dispatch(deletePhotoSuccess());
            dispatch(loadPhotosByGallery(galleryId));
        } catch (error) {
            dispatch(deletePhotoError(error.message));
        }
    };
};

// Get photo by ID actions
export const getPhotoStart = () => {
    return { type: GET_PHOTO_START };
};

export const getPhotoSuccess = (photo) => {
    return { type: GET_PHOTO_SUCCESS, payload: photo };
};

export const getPhotoError = (errorMessage) => {
    return { type: GET_PHOTO_ERROR, payload: errorMessage };
};

export const getPhoto = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getPhotoStart());
            const firestore = getFirestore();
            const photoRef = doc(firestore, "photos", id);
            const photoSnapshot = await getDoc(photoRef);
            if (photoSnapshot.exists()) {
                const photo = photoSnapshot.data();
                const storage = getStorage();
                const photoRef = ref(storage, photo.path);
                const photoURL = await getDownloadURL(photoRef);
                const updatedPhoto = { ...photo, photoURL };

                dispatch(getPhotoSuccess(updatedPhoto));
            } else {
                dispatch(getPhotoError("Photo not found"));
            }
        } catch (error) {
            dispatch(getPhotoError(error.message));
        }
    };
};

// Update photo actions
export const updatePhotoStart = () => {
    return { type: UPDATE_PHOTO_START };
};

export const updatePhotoSuccess = () => {
    return { type: UPDATE_PHOTO_SUCCESS };
};

export const updatePhotoError = (errorMessage) => {
    return { type: UPDATE_PHOTO_ERROR, payload: errorMessage };
};

export const updatePhoto = (id, photo, image, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updatePhotoStart());
            const firestore = getFirestore();
            const photoRef = doc(firestore, "photos", id);
            const photoDoc = await getDoc(photoRef);
            let photoData = photoDoc.data();
            photoData = { ...photoData, ...photo }

            if (!!image) {
                const storage = getStorage();
                const oldPath = photoData.path;
                if (oldPath) {
                    const oldRef = ref(storage, oldPath);
                    await deleteObject(oldRef);
                }

                const storagePath = `photos/${id}.${getFileExtension(image.name)}`;
                const imageRef = ref(storage, storagePath);
                await uploadBytes(imageRef, image);
                photoData.path = storagePath;
            }

            await setDoc(photoRef, photoData);
            dispatch(updatePhotoSuccess());
            navigate('/admin/photos?galleryId=' + photo.galleryId);
        } catch (error) {
            dispatch(updatePhotoError(error.message));
        }
    };
};

// Load photos actions
export const loadPhotosByGalleryStart = () => {
    return { type: LOAD_PHOTOS_BY_GALLERY_START };
};

export const loadPhotosByGallerySuccess = (photos) => {
    return { type: LOAD_PHOTOS_BY_GALLERY_SUCCESS, payload: photos };
};

export const loadPhotosByGalleryError = (errorMessage) => {
    return { type: LOAD_PHOTOS_BY_GALLERY_ERROR, payload: errorMessage };
};

export const loadPhotosByGallery = (gallery) => {
    return async (dispatch) => {
        try {
            dispatch(loadPhotosByGalleryStart());
            if (!gallery) {
                dispatch(loadPhotosByGallerySuccess([]));
            } else {
                const firestore = getFirestore();
                const photosQuerySnapshot = gallery === 'ALL' ? await getDocs(query(collection(firestore, "photos"))) : await getDocs(query(collection(firestore, "photos"), where("galleryId", "==", gallery)));
                const photos = photosQuerySnapshot.docs.map((doc) => doc.data());
                const galleriesQuerySnapshot = await getDocs(collection(firestore, "galleries"));
                const galleries = galleriesQuerySnapshot.docs.map((doc) => doc.data());

                const galleriesMap = new Map();
                galleries.forEach((g) => {
                    galleriesMap.set(g.id, g.galleryName);
                });

                photos.forEach((p) => {
                    p.galleryName = galleriesMap.get(p.galleryId);
                });
                dispatch(loadPhotosByGallerySuccess(photos));
            }
        } catch (error) {
            dispatch(loadPhotosByGalleryError(error.message));
        }
    };
};

// Load photos actions
export const loadSlideshowStart = () => {
    return { type: LOAD_SLIDESHOW_START };
};

export const loadSlideshowSuccess = (photos) => {
    return { type: LOAD_SLIDESHOW_SUCCESS, payload: photos };
};

export const loadSlideshowError = (errorMessage) => {
    return { type: LOAD_SLIDESHOW_ERROR, payload: errorMessage };
};

export const loadSlideshow = () => {
    return async (dispatch) => {
        try {
            dispatch(loadSlideshowStart());

            const firestore = getFirestore();
            const photosQuerySnapshot = await getDocs(query(collection(firestore, "photos"), where("includeInSlideshow", "==", true)));
            const photos = photosQuerySnapshot.docs.map((doc) => doc.data());

            const urls = await Promise.all(photos.map((p) => {
                const storage = getStorage();
                const photoRef = ref(storage, p.path);
                return getDownloadURL(photoRef);
            }));
            
            dispatch(loadSlideshowSuccess(urls));
        } catch (error) {
            dispatch(loadSlideshowError(error.message));
        }
    };
};