import { GET_GALLERY_SUCCESS, LOAD_GALLERIES_BY_CATEGORY_SUCCESS, LOAD_GALLERIES_SUCCESS } from "../actions/galleryActions";

// Initial state
const initialState = {
    galleries: null,
    gallery: null,
    galleriesByCategory: null,
};

// Reducer function
export const gallery = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: action.payload,
            };
        case GET_GALLERY_SUCCESS:
            return {
                ...state,
                gallery: action.payload,
            };
            case LOAD_GALLERIES_BY_CATEGORY_SUCCESS:
                return {
                    ...state,
                    galleriesByCategory: action.payload,
                };
        default:
            return state;
    }
};
