import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadGalleries } from '../actions/galleryActions';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { adminPageRootStyle } from './styles';
import { getPhoto, updatePhoto } from '../actions/photoActions';
import PhotoEditForm from './PhotoEditForm';

const PhotoEditPage = () => {
    const [photo, setPhoto] = useState(null);
    const [image, setImage] = useState('');
    const [photoSrc, setPhotoSrc] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { photoId } = useParams();

    const galleries = useSelector(state => state.gallery.galleries);
    const photoRedux = useSelector(state => state.photo.photo);

    useEffect(() => {
        dispatch(loadGalleries());
        dispatch(getPhoto(photoId));
    }, [dispatch, photoId]);

    useEffect(() => {
        if (photoRedux) {
            setPhoto({ ...photoRedux });
            setPhotoSrc(photoRedux.photoURL);
        }
    }, [photoRedux]);

    const handlePhotoChange = useCallback((p) => {
        setPhoto(p);
    }, []);

    const handleImageChange = useCallback(({ file, fileURL }) => {
        setPhotoSrc(fileURL);
        setImage(file);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(updatePhoto(photoId, photo, image, navigate));
    }, [photoId, photo, image, navigate, dispatch]);

    return (
        <Paper style={adminPageRootStyle}>
            <h2>Edit Photo</h2>
            <PhotoEditForm
                handleSubmit={handleSubmit}
                photo={photo}
                handlePhotoChange={handlePhotoChange}
                photoSrc={photoSrc}
                handleImageChange={handleImageChange}
                galleries={galleries}
            />
        </Paper>
    );
};

export default PhotoEditPage;
