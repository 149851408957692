import './ContactsPage.css';
import React from 'react';
import Paper from '@mui/material/Paper';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';

function ContactsPage() {
    return (
        <Paper className='contacts-page'>
            <div className="contact-item">
                <EmailIcon />
                <a href="mailto:me@valeter.net">me@valeter.net</a>
            </div>
            <div className="contact-item">
                <InstagramIcon />
                <a href="https://www.instagram.com/ValeTer_">ValeTer_</a>
            </div>
            <div className="contact-item">
                <FacebookIcon />
                <a href="https://www.facebook.com/ValeTerPhotography">ValeTerPhotography</a>
            </div>
        </Paper>
    );
}

export default ContactsPage;
