import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Helmet>
          <title>Seattle Area Landscape Photographer | ValeTer | Landscape and Nature Photography Prints</title>
          <meta name="description" content="Seattle Area Landscape Photographer | ValeTer(Valerii Tereshchenko) | Landscape and Nature Photography Prints" />
          <meta name="keywords" content="Seattle landscape photographer, Nature photography prints, Pacific Northwest landscape photography, Professional nature photographer Seattle, Landscape photography USA" />
          <meta property="og:title" content="Stunning Landscape Photography by ValeTer" />
          <meta property="og:description" content="Explore the beauty of nature through the lens of a Seattle-based landscape photographer." />
          {/* <meta property="og:image" content="TBD" /> */}
          <meta property="og:url" content="landscapes.valeter.net" />
          <meta property="og:site_name" content="Landscape and Nature Photography by ValeTer (Valerii Tereshchenko)"></meta>
        </Helmet>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
