import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { generateKey } from "../common/utils/keyUtils";
import { getFileExtension } from "../common/utils/fileUtils";
import { getFirestore, collection, setDoc, getDocs, deleteDoc, doc, getDoc, query, where } from "firebase/firestore";

// Action types
export const CREATE_GALLERY_START = 'CREATE_GALLERY_START';
export const CREATE_GALLERY_SUCCESS = 'CREATE_GALLERY_SUCCESS';
export const CREATE_GALLERY_ERROR = 'CREATE_GALLERY_ERROR';
export const LOAD_GALLERIES_START = 'LOAD_GALLERIES_START';
export const LOAD_GALLERIES_SUCCESS = 'LOAD_GALLERIES_SUCCESS';
export const LOAD_GALLERIES_ERROR = 'LOAD_GALLERIES_ERROR';
export const DELETE_GALLERY_START = 'DELETE_GALLERY_START';
export const DELETE_GALLERY_SUCCESS = 'DELETE_GALLERY_SUCCESS';
export const DELETE_GALLERY_ERROR = 'DELETE_GALLERY_ERROR';
export const GET_GALLERY_START = 'GET_GALLERY_START';
export const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS';
export const GET_GALLERY_ERROR = 'GET_GALLERY_ERROR';
export const UPDATE_GALLERY_START = 'UPDATE_GALLERY_START';
export const UPDATE_GALLERY_SUCCESS = 'UPDATE_GALLERY_SUCCESS';
export const UPDATE_GALLERY_ERROR = 'UPDATE_GALLERY_ERROR';
export const LOAD_GALLERIES_BY_CATEGORY_START = 'LOAD_GALLERIES_BY_CATEGORY_START';
export const LOAD_GALLERIES_BY_CATEGORY_SUCCESS = 'LOAD_GALLERIES_BY_CATEGORY_SUCCESS';
export const LOAD_GALLERIES_BY_CATEGORY_ERROR = 'LOAD_GALLERIES_BY_CATEGORY_ERROR';


// Create gallery actions
export const createGalleryStart = () => {
    return { type: CREATE_GALLERY_START };
};

export const createGallerySuccess = () => {
    return { type: CREATE_GALLERY_SUCCESS };
};

export const createGalleryError = (errorMessage) => {
    return { type: CREATE_GALLERY_ERROR, payload: errorMessage };
};

export const createGallery = (gallery, coverImage, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createGalleryStart());
            const storage = getStorage();
            const id = generateKey();
            const storagePath = `gallery-covers/${id}.${getFileExtension(coverImage.name)}`;
            const imageRef = ref(storage, storagePath);
            await uploadBytes(imageRef, coverImage);

            const galleryData = {
                ...gallery,
                coverPath: storagePath,
                id,
            };

            const firestore = getFirestore();
            await setDoc(doc(firestore, "galleries", id), galleryData);
            navigate('/admin/galleries');
            dispatch(createGallerySuccess());
        } catch (error) {
            dispatch(createGalleryError(error.message));
        }
    };
};

// Load galleries actions
export const loadGalleriesStart = () => {
    return { type: LOAD_GALLERIES_START };
};

export const loadGalleriesSuccess = (galleries) => {
    return { type: LOAD_GALLERIES_SUCCESS, payload: galleries };
};

export const loadGalleriesError = (errorMessage) => {
    return { type: LOAD_GALLERIES_ERROR, payload: errorMessage };
};

export const loadGalleries = () => {
    return async (dispatch) => {
        try {
            dispatch(loadGalleriesStart());
            const firestore = getFirestore();
            const galleriesQuerySnapshot = await getDocs(collection(firestore, "galleries"));
            const galleries = galleriesQuerySnapshot.docs.map((doc) => doc.data());
            const categoriesQuerySnapshot = await getDocs(collection(firestore, "categories"));
            const categories = categoriesQuerySnapshot.docs.map((doc) => doc.data());

            const categoriesMap = new Map();
            categories.forEach((category) => {
                categoriesMap.set(category.id, category.categoryName);
            });

            galleries.forEach((gallery) => {
                gallery.categoryName = categoriesMap.get(gallery.categoryId);
            });

            galleries.sort((a, b) => (a.categoryName + ">" + a.galleryName).localeCompare(b.categoryName + ">" + b.galleryName));

            dispatch(loadGalleriesSuccess(galleries));
        } catch (error) {
            dispatch(loadGalleriesError(error.message));
        }
    };
};

// Delete gallery actions
export const deleteGalleryStart = () => {
    return { type: DELETE_GALLERY_START };
};

export const deleteGallerySuccess = () => {
    return { type: DELETE_GALLERY_SUCCESS };
};

export const deleteGalleryError = (errorMessage) => {
    return { type: DELETE_GALLERY_ERROR, payload: errorMessage };
};

export const deleteGallery = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteGalleryStart());
            const firestore = getFirestore();
            const galleryRef = doc(firestore, "galleries", id);
            await deleteDoc(galleryRef);
            dispatch(deleteGallerySuccess());
            dispatch(loadGalleries());
        } catch (error) {
            dispatch(deleteGalleryError(error.message));
        }
    };
};

// Get gallery by ID actions
export const getGalleryStart = () => {
    return { type: GET_GALLERY_START };
};

export const getGallerySuccess = (gallery) => {
    return { type: GET_GALLERY_SUCCESS, payload: gallery };
};

export const getGalleryError = (errorMessage) => {
    return { type: GET_GALLERY_ERROR, payload: errorMessage };
};

export const getGallery = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getGalleryStart());
            const firestore = getFirestore();
            const galleryRef = doc(firestore, "galleries", id);
            const gallerySnapshot = await getDoc(galleryRef);
            if (gallerySnapshot.exists()) {
                const gallery = gallerySnapshot.data();
                const storage = getStorage();
                const coverRef = ref(storage, gallery.coverPath);
                const coverURL = await getDownloadURL(coverRef);
                const updatedGallery = { ...gallery, coverURL };

                dispatch(getGallerySuccess(updatedGallery));
            } else {
                dispatch(getGalleryError("Gallery not found"));
            }
        } catch (error) {
            dispatch(getGalleryError(error.message));
        }
    };
};

// Update gallery actions
export const updateGalleryStart = () => {
    return { type: UPDATE_GALLERY_START };
};

export const updateGallerySuccess = () => {
    return { type: UPDATE_GALLERY_SUCCESS };
};

export const updateGalleryError = (errorMessage) => {
    return { type: UPDATE_GALLERY_ERROR, payload: errorMessage };
};

export const updateGallery = (id, gallery, coverImage, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updateGalleryStart());
            const firestore = getFirestore();
            const galleryRef = doc(firestore, "galleries", id);
            const galleryDoc = await getDoc(galleryRef);
            let galleryData = galleryDoc.data();
            galleryData = { ...galleryData, ...gallery }

            if (!!coverImage) {
                const storage = getStorage();
                const oldCoverPath = galleryData.coverPath;
                if (oldCoverPath) {
                    const oldCoverRef = ref(storage, oldCoverPath);
                    await deleteObject(oldCoverRef);
                }

                const storagePath = `gallery-covers/${id}.${getFileExtension(coverImage.name)}`;
                const imageRef = ref(storage, storagePath);
                await uploadBytes(imageRef, coverImage);
                galleryData.coverPath = storagePath;
            }

            await setDoc(galleryRef, galleryData);
            dispatch(loadGalleries());
            navigate('/admin/galleries');
            dispatch(updateGallerySuccess());
        } catch (error) {
            dispatch(updateGalleryError(error.message));
        }
    };
};

// Load galleries actions
export const loadGalleriesByCategoryStart = () => {
    return { type: LOAD_GALLERIES_BY_CATEGORY_START };
};

export const loadGalleriesByCategorySuccess = (galleries) => {
    return { type: LOAD_GALLERIES_BY_CATEGORY_SUCCESS, payload: galleries };
};

export const loadGalleriesByCategoryError = (errorMessage) => {
    return { type: LOAD_GALLERIES_BY_CATEGORY_ERROR, payload: errorMessage };
};

export const loadGalleriesByCategory = (category) => {
    return async (dispatch) => {
        try {
            dispatch(loadGalleriesByCategoryStart());
            const firestore = getFirestore();
            const galleriesQuerySnapshot = await getDocs(query(collection(firestore, "galleries"), where("categoryId", "==", category)));
            const galleries = galleriesQuerySnapshot.docs.map((doc) => doc.data());
            
            dispatch(loadGalleriesByCategorySuccess(galleries));
        } catch (error) {
            dispatch(loadGalleriesByCategoryError(error.message));
        }
    };
};