import { LOGIN_SUCCESS } from "../actions/authActions";

// Initial state
const initialState = {
    isAuthenticated: false,
    user: null,
};

// Reducer function
export function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: true, user: action.payload };
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: false, user: null };
        default:
            return state;
    }
};
