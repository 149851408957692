import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FbImage from '../common/components/fb_image/FbImage';
import { loadGalleries } from '../actions/galleryActions';
import { adminPageRootStyle } from './styles';
import { deletePhoto, loadPhotosByGallery } from '../actions/photoActions';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';

function PhotosListPage() {
    const dispatch = useDispatch();
    const galleries = useSelector(state => state.gallery.galleries);
    const photos = useSelector(state => state.photo.photosByGallery);
    const navigate = useNavigate();

    const location = useLocation();
    // Parse the query string
    const queryParams = new URLSearchParams(location.search);
    const selectedGalleryId = queryParams.get('galleryId');

    useEffect(() => {
        dispatch(loadGalleries());
    }, [dispatch]);


    useEffect(() => {
        dispatch(loadPhotosByGallery(selectedGalleryId));
    }, [dispatch, selectedGalleryId]);

    const handleDelete = (id) => {
        dispatch(deletePhoto(id));
    };

    const handleSelectedGalleryChange = (event) => {
        navigate(`/admin/photos?galleryId=${event.target.value}`);
    };

    return (
        <Paper style={adminPageRootStyle}>
            <h1>Photos</h1>
            <Button variant="contained" href="/admin/photos/add" sx={{ alignSelf: 'flex-start' }}>
                Add Photo
            </Button>
            <Select
                value={selectedGalleryId}
                onChange={handleSelectedGalleryChange}
                label="Gallery"
                sx={{ mt: 2, mb: 2, maxWidth: 600 }}
            >
                <MenuItem key='ALL' value='ALL'>(All)</MenuItem>
                {(galleries ?? []).map(gallery => {
                    return <MenuItem key={gallery.id} value={gallery.id}>{gallery.categoryName} - {gallery.galleryName}</MenuItem>
                })}
            </Select>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Gallery</TableCell>
                            <TableCell>Cover</TableCell>
                            <TableCell>Is panorama</TableCell>
                            <TableCell>Include in slideshow</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(photos ?? []).map((photo) => (
                            <TableRow
                                key={photo.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {photo.id}
                                </TableCell>
                                <TableCell>{photo.photoName}</TableCell>
                                <TableCell>{photo.galleryName}</TableCell>
                                <TableCell>
                                    <FbImage
                                        path={photo.path}
                                        style={{
                                            objectFit: 'cover',
                                            width: '120px',
                                            height: '100px'
                                        }} />
                                </TableCell>
                                <TableCell>{photo.isPanorama ? 'Yes' : ''}</TableCell>
                                <TableCell>{photo.includeInSlideshow ? 'Yes' : ''}</TableCell>
                                <TableCell align="right">
                                    <IconButton href={`/admin/photos/edit/${photo.id}`}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(photo.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default PhotosListPage;
