import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FbImage from '../common/components/fb_image/FbImage';
import { deleteGallery, loadGalleries } from '../actions/galleryActions';
import { adminPageRootStyle } from './styles';

const GalleriesListPage = () => {
    const dispatch = useDispatch();
    const galleries = useSelector(state => state.gallery.galleries);

    useEffect(() => {
        dispatch(loadGalleries());
    }, [dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteGallery(id));
    };

    return (
        <Paper style={adminPageRootStyle}>
            <h1>Galleries</h1>
            <Button variant="contained" href="/admin/galleries/add" sx={{ alignSelf: 'flex-start' }}>
                Add Gallery
            </Button>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Cover</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(galleries ?? []).map((gallery) => (
                            <TableRow
                                key={gallery.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {gallery.id}
                                </TableCell>
                                <TableCell>{gallery.galleryName}</TableCell>
                                <TableCell>{gallery.categoryName}</TableCell>
                                <TableCell>
                                    <FbImage
                                        path={gallery.coverPath}
                                        style={{
                                            objectFit: 'cover',
                                            width: '120px',
                                            height: '100px'
                                        }} />
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton href={`/admin/galleries/edit/${gallery.id}`}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(gallery.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default GalleriesListPage;
