import { DECREMENT_COUNTER, INCREMENT_COUNTER } from '../actions/counterActions';

const defaultState = 0;

export function counter(state = defaultState, action) {
    switch (action.type) {
      case INCREMENT_COUNTER:
        return state + 1;
      case DECREMENT_COUNTER:
        return state - 1;
      default:
        return state;
    }
  }