import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const FbImage = ({ path, alt, ...rest }) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        try {
            setLoading(true);
            const storage = getStorage();
            const storageRef = ref(storage, path);
            getDownloadURL(storageRef).then((coverURL) => {
                setUrl(coverURL);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
        }
    }, [path]);

    return (
        loading ? null : <img
            src={url}
            alt={alt}
            loading="lazy"
            {...rest}
        />
    );
};

export default FbImage;
