import './GalleriesByCategoryPage.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import FbImage from '../common/components/fb_image/FbImage';
import { useParams } from 'react-router-dom';
import { loadGalleriesByCategory } from '../actions/galleryActions';
import { getCategory } from '../actions/categoryActions';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function GalleriesByCategoryPage() {

    const dispatch = useDispatch();
    const galleries = useSelector(state => state.gallery.galleriesByCategory);
    const category = useSelector(state => state.category.category);

    const { categoryId } = useParams();

    useEffect(() => {
        dispatch(loadGalleriesByCategory(categoryId));
        dispatch(getCategory(categoryId));
    }, [dispatch, categoryId]);


    return (
        <Paper className='galleries-by-category-page'>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/galleries">
                    Galleries
                </Link>
                <Typography color="text.primary">{category?.categoryName}</Typography>
            </Breadcrumbs>
            <ImageList
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    gridTemplateColumns:
                        'repeat(auto-fill, minmax(400px, 12fr))!important',
                    justifyContent: 'center',
                }}
                cols={3}
                gap={8}
            >
                {(galleries ?? []).map((g) => (
                    <ImageListItem key={g.id} className='galery-card'>
                        <div className='cell'>
                            <a className='cell-content' href={`/galleries/photos/${g.id}`}>
                                <FbImage
                                    path={g.coverPath}
                                    alt={g.galleryName}
                                />
                            </a>
                            <ImageListItemBar
                                sx={{
                                    '.MuiImageListItemBar-title': {
                                        fontSize: '24px',
                                    },
                                }}
                                title={g.galleryName}
                            />
                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
        </Paper>
    );
}

export default GalleriesByCategoryPage;
