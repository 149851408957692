import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCategory } from '../actions/categoryActions';
import { useNavigate } from 'react-router-dom';
import CategoryEditForm from './CategoryEditForm';
import Paper from '@mui/material/Paper';
import { adminPageRootStyle } from './styles';

const CategoryAddPage = () => {
    const [category, setCategory] = useState(null);
    const [coverImage, setCoverImage] = useState('');
    const [coverSrc, setCoverSrc] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleCategoryChange = useCallback((c) => {
        setCategory(c);
    }, []);

    const handleCoverImageChange = useCallback(({ file, fileURL }) => {
        setCoverSrc(fileURL);
        setCoverImage(file);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(createCategory(category, coverImage, navigate));
    }, [category, coverImage, dispatch, navigate]);

    return (
        <Paper style={adminPageRootStyle}>
            <h2>Add Category</h2>
            <CategoryEditForm
                handleSubmit={handleSubmit}
                category={category}
                handleCategoryChange={handleCategoryChange}
                coverSrc={coverSrc}
                handleCoverImageChange={handleCoverImageChange}
            />
        </Paper>
    );
};

export default CategoryAddPage;
