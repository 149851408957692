import React, { useCallback, useState } from 'react';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { selectedImageStyle, noImageDivStyle, VisuallyHiddenInput, formStyle } from './styles';


const GalleryEditForm = ({ handleSubmit, gallery, categories, handleGalleryChange, coverSrc, handleCoverImageChange }) => {
    const [changed, setChanged] = useState(false);

    const handleCoverImageChangeInternal = useCallback((e) => {
        const file = e.target.files[0]
        if (!file) {
            return;
        }

        const fileURL = URL.createObjectURL(file);
        handleCoverImageChange({ file, fileURL });
        setChanged(true);
    }, [handleCoverImageChange]);

    const handleGalleryNameChange = useCallback((e) => {
        handleGalleryChange({ ...gallery, galleryName: e.target.value });
        setChanged(true);
    }, [gallery, handleGalleryChange]);

    const handleCategoryChangeInternal = useCallback((e) => {
        handleGalleryChange({ ...gallery, categoryId: e.target.value });
        setChanged(true);
    }, [gallery, handleGalleryChange]);

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <TextField
                label="Gallery Name"
                value={gallery?.galleryName || ""}
                margin="normal"
                onChange={handleGalleryNameChange}
                sx={{ minWidth: 300 }}
            />
            <Select
                label="Category"
                value={gallery?.categoryId || ''}
                onChange={handleCategoryChangeInternal}
                sx={{ minWidth: 300 }}
            >
                {(categories ?? []).map((category) => (
                    <MenuItem key={category.id} value={category.id}>{category.categoryName}</MenuItem>
                ))}
            </Select>
            {!!coverSrc ? <img src={coverSrc} style={selectedImageStyle} alt='Gallery Cover' /> : <div style={noImageDivStyle}>Choose cover image</div>}
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} margin="dense">
                Cover Image
                <VisuallyHiddenInput type="file" onChange={handleCoverImageChangeInternal} />
            </Button>
            <Button type="submit" variant="contained" onClick={handleSubmit} disabled={!changed || !coverSrc || !gallery?.galleryName || !gallery?.categoryId} style={{ alignSelf: 'center' }}>Save</Button>
        </form >
    );
};

export default GalleryEditForm;
