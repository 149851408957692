import './PhotosPage.css';
import React, { useEffect } from 'react';

import './PhotoPage.css';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import FbImage from '../common/components/fb_image/FbImage';
import { useParams } from 'react-router-dom';
import { getPhoto } from '../actions/photoActions';
import { getGallery } from '../actions/galleryActions';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getCategory } from '../actions/categoryActions';

function PhotoPage() {
    const dispatch = useDispatch();
    const photo = useSelector(state => state.photo.photo);
    const gallery = useSelector(state => state.gallery.gallery);
    const category = useSelector(state => state.category.category);

    const { photoId } = useParams();

    useEffect(() => {
        dispatch(getPhoto(photoId));
    }, [dispatch, photoId]);

    useEffect(() => {
        if (photo?.galleryId) dispatch(getGallery(photo?.galleryId));
    }, [dispatch, photo]);

    useEffect(() => {
        if (gallery) dispatch(getCategory(gallery?.categoryId));
    }, [dispatch, gallery]);

    return (
        <Paper className='photo-page'>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/galleries">
                    Galleries
                </Link>
                <Link underline="hover" color="inherit" href={`/galleries/${category?.id}`}>
                    {category?.categoryName}
                </Link>
                <Link underline="hover" color="inherit" href={`/galleries/photos/${gallery?.id}`}>
                    {gallery?.galleryName}
                </Link>
                <Typography color="text.primary">{photo?.photoName}</Typography>
            </Breadcrumbs>
            <FbImage
                path={photo?.path}
                alt={photo?.photoName}
            />
            <div className='photo-info'>{photo?.photoName}</div>
        </Paper>
    );
}

export default PhotoPage;
