import './PhotosPage.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FbImage from '../common/components/fb_image/FbImage';
import { useParams } from 'react-router-dom';
import { loadPhotosByGallery } from '../actions/photoActions';
import { getGallery } from '../actions/galleryActions';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getCategory } from '../actions/categoryActions';

const PhotosPage = () => {
    const dispatch = useDispatch();
    const photos = useSelector(state => state.photo.photosByGallery);
    const gallery = useSelector(state => state.gallery.gallery);
    const category = useSelector(state => state.category.category);

    const { galleryId } = useParams();

    useEffect(() => {
        dispatch(loadPhotosByGallery(galleryId));
        dispatch(getGallery(galleryId));
    }, [dispatch, galleryId]);

    useEffect(() => {
        if (gallery) dispatch(getCategory(gallery?.categoryId));
    }, [dispatch, gallery]);


    return (
        <Paper className='photos-page'>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/galleries">
                    Galleries
                </Link>
                <Link underline="hover" color="inherit" href={`/galleries/${category?.id}`}>
                    {category?.categoryName}
                </Link>
                <Typography color="text.primary">{gallery?.galleryName}</Typography>
            </Breadcrumbs>
            <ImageList
                variant="masonry"
                sx={{
                    width: '100%',
                    margin: '8px',
                }}
                gap={8}
            >
                {(photos ?? []).filter(p => !p.isPanorama).map((p) => (
                    <ImageListItem key={p.id} className='photo-card'>
                        <div className='cell'>
                            <a className='cell-content' href={`/galleries/photo/${p.id}`}>
                                <FbImage
                                    path={p.path}
                                    alt={p.photoName}
                                />
                            </a>
                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
            {(photos ?? []).filter(p => !!p.isPanorama).map((p) => (
                <a className='panorama' href={`/galleries/photo/${p.id}`}>
                    <FbImage
                        path={p.path}
                        alt={p.photoName}
                    />
                </a>
            ))}
        </Paper>
    );
};

export default PhotosPage;
