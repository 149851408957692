
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { generateKey } from "../common/utils/keyUtils";
import { getFileExtension } from "../common/utils/fileUtils";
import { getFirestore, collection, setDoc, getDocs, deleteDoc, doc, getDoc } from "firebase/firestore";

// Action types
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';
export const LOAD_CATEGORIES_START = 'LOAD_CATEGORIES_START';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const GET_CATEGORY_START = 'GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

// Create category actions
export const createCategoryStart = () => {
    return { type: CREATE_CATEGORY_START };
};

export const createCategorySuccess = () => {
    return { type: CREATE_CATEGORY_SUCCESS };
};

export const createCategoryError = (errorMessage) => {
    return { type: CREATE_CATEGORY_ERROR, payload: errorMessage };
};

export const createCategory = (category, coverImage, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(createCategoryStart());
            const storage = getStorage();
            const id = generateKey();
            const storagePath = `category-covers/${id}.${getFileExtension(coverImage.name)}`;
            const imageRef = ref(storage, storagePath);
            await uploadBytes(imageRef, coverImage);

            const categoryData = {
                ...category,
                coverPath: storagePath,
                id,
            };

            const firestore = getFirestore();
            await setDoc(doc(firestore, "categories", id), categoryData);
            navigate('/admin/categories');
            dispatch(createCategorySuccess());
        } catch (error) {
            dispatch(createCategoryError(error.message));
        }
    };
};

// Load categories actions
export const loadCategoriesStart = () => {
    return { type: LOAD_CATEGORIES_START };
};

export const loadCategoriesSuccess = (categories) => {
    return { type: LOAD_CATEGORIES_SUCCESS, payload: categories };
};

export const loadCategoriesError = (errorMessage) => {
    return { type: LOAD_CATEGORIES_ERROR, payload: errorMessage };
};

export const loadCategories = () => {
    return async (dispatch) => {
        try {
            dispatch(loadCategoriesStart());
            const firestore = getFirestore();
            const querySnapshot = await getDocs(collection(firestore, "categories"));
            const categories = querySnapshot.docs.map((doc) => doc.data());
            dispatch(loadCategoriesSuccess(categories));
        } catch (error) {
            dispatch(loadCategoriesError(error.message));
        }
    };
};

// Delete category actions
export const deleteCategoryStart = () => {
    return { type: DELETE_CATEGORY_START };
};

export const deleteCategorySuccess = () => {
    return { type: DELETE_CATEGORY_SUCCESS };
};

export const deleteCategoryError = (errorMessage) => {
    return { type: DELETE_CATEGORY_ERROR, payload: errorMessage };
};

export const deleteCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch(deleteCategoryStart());
            const firestore = getFirestore();
            const categoryRef = doc(firestore, "categories", id);
            await deleteDoc(categoryRef);
            dispatch(deleteCategorySuccess());
            dispatch(loadCategories());
        } catch (error) {
            dispatch(deleteCategoryError(error.message));
        }
    };
};

// Get category by ID actions
export const getCategoryStart = () => {
    return { type: GET_CATEGORY_START };
};

export const getCategorySuccess = (category) => {
    return { type: GET_CATEGORY_SUCCESS, payload: category };
};

export const getCategoryError = (errorMessage) => {
    return { type: GET_CATEGORY_ERROR, payload: errorMessage };
};

export const getCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch(getCategoryStart());
            const firestore = getFirestore();
            const categoryRef = doc(firestore, "categories", id);
            const categorySnapshot = await getDoc(categoryRef);
            if (categorySnapshot.exists()) {
                const category = categorySnapshot.data();
                const storage = getStorage();
                const coverRef = ref(storage, category.coverPath);
                const coverURL = await getDownloadURL(coverRef);
                const updatedCategory = { ...category, coverURL };

                dispatch(getCategorySuccess(updatedCategory));
            } else {
                dispatch(getCategoryError("Category not found"));
            }
        } catch (error) {
            dispatch(getCategoryError(error.message));
        }
    };
};

// Update category actions
export const updateCategoryStart = () => {
    return { type: UPDATE_CATEGORY_START };
};

export const updateCategorySuccess = () => {
    return { type: UPDATE_CATEGORY_SUCCESS };
};

export const updateCategoryError = (errorMessage) => {
    return { type: UPDATE_CATEGORY_ERROR, payload: errorMessage };
};

export const updateCategory = (id, category, coverImage, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(updateCategoryStart());
            const firestore = getFirestore();
            const categoryRef = doc(firestore, "categories", id);
            const categoryDoc = await getDoc(categoryRef);
            let categoryData = categoryDoc.data();
            categoryData = { ...categoryData, ...category }

            if (!!coverImage) {
                const storage = getStorage();
                const oldCoverPath = categoryData.coverPath;
                if (oldCoverPath) {
                    const oldCoverRef = ref(storage, oldCoverPath);
                    await deleteObject(oldCoverRef);
                }

                const storagePath = `category-covers/${id}.${getFileExtension(coverImage.name)}`;
                const imageRef = ref(storage, storagePath);
                await uploadBytes(imageRef, coverImage);
                categoryData.coverPath = storagePath;
            }

            await setDoc(categoryRef, categoryData);
            dispatch(updateCategorySuccess());
            dispatch(loadCategories());
            navigate('/admin/categories');
        } catch (error) {
            dispatch(updateCategoryError(error.message));
        }
    };
};
