import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGallery } from '../actions/galleryActions';
import { useNavigate } from 'react-router-dom';
import GalleryEditForm from './GalleryEditForm';
import Paper from '@mui/material/Paper';
import { loadCategories } from '../actions/categoryActions';
import { adminPageRootStyle } from './styles';

const GalleryAddPage = () => {
    const [gallery, setGallery] = useState(null);
    const [coverImage, setCoverImage] = useState('');
    const [coverSrc, setCoverSrc] = useState('');

    const categories = useSelector(state => state.category.categories);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(loadCategories());
    }, [dispatch]);

    const handleGalleryChange = useCallback((g) => {
        setGallery(g);
    }, []);

    const handleCoverImageChange = useCallback(({ file, fileURL }) => {
        setCoverSrc(fileURL);
        setCoverImage(file);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(createGallery(gallery, coverImage, navigate));
    }, [gallery, coverImage, navigate, dispatch]);

    return (
        <Paper style={adminPageRootStyle}>
            <h2>Add Gallery</h2>
            <GalleryEditForm
                handleSubmit={handleSubmit}
                gallery={gallery}
                handleGalleryChange={handleGalleryChange}
                coverSrc={coverSrc}
                handleCoverImageChange={handleCoverImageChange}
                categories={categories}
            />
        </Paper>
    );
};

export default GalleryAddPage;
