import { LOAD_PHOTOS_BY_GALLERY_SUCCESS, GET_PHOTO_SUCCESS, LOAD_SLIDESHOW_SUCCESS } from "../actions/photoActions";

// Initial state
const initialState = {
    photo: null,
    photosByGallery: null,
    slideshowUrls: null,
};

// Reducer function
export const photo = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PHOTOS_BY_GALLERY_SUCCESS:
            return {
                ...state,
                photosByGallery: action.payload,
            };
        case GET_PHOTO_SUCCESS:
            return {
                ...state,
                photo: action.payload,
            };
        case LOAD_SLIDESHOW_SUCCESS:
            return {
                ...state,
                slideshowUrls: action.payload,
            };
        default:
            return state;
    }
};
