import './GalleriesPage.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { loadCategories } from '../actions/categoryActions';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import FbImage from '../common/components/fb_image/FbImage';

function GalleriesPage() {

    const dispatch = useDispatch();
    const categories = useSelector(state => state.category.categories);

    useEffect(() => {
        dispatch(loadCategories());
    }, [dispatch]);


    return (
        <Paper className='galleries-page'>
            <ImageList
                sx={{
                    width: '100%',
                    overflow: 'hidden',
                    gridTemplateColumns:
                        'repeat(auto-fill, minmax(400px, 12fr))!important',
                    justifyContent: 'center',
                    margin: '8px',
                }}
                cols={3}
                gap={8}
            >
                {(categories ?? []).map((g) => (
                    <ImageListItem key={g.id} className='galery-card'>
                        <div className='cell'>
                            <a className='cell-content' href={`/galleries/${g.id}`}>
                                <FbImage
                                    path={g.coverPath}
                                    alt={g.categoryName}
                                />
                            </a>
                            <ImageListItemBar
                                sx={{
                                    '.MuiImageListItemBar-title': {
                                        fontSize: '24px',
                                    },
                                }}
                                title={g.categoryName}
                            />
                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
        </Paper>
    );
}

export default GalleriesPage;
