import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './admin/AdminPage';
import MainPage from './portfolio/MainPage';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from './actions/errorActions';
import ErrorDialog from './common/components/error_dialog/ErrorDialog';
import CategoriesListPage from './admin/CategoriesListPage';
import PhotosListPage from './admin/PhotosListPage';
import CategoryAddPage from './admin/CategoryAddPage';
import CategoryEditPage from './admin/CategoryEditPage';
import CircularProgress from '@mui/material/CircularProgress';
import GalleriesPage from './portfolio/GalleriesPage';
import AboutPage from './portfolio/AboutPage';
import ContactsPage from './portfolio/ContactsPage';
import GalleriesListPage from './admin/GalleriesListPage';
import GalleryAddPage from './admin/GalleryAddPage';
import GalleryEditPage from './admin/GalleryEditPage';
import Overlay from './common/components/overlay/Overlay';
import GalleriesByCategoryPage from './portfolio/GalleriesByCategoryPage';
import HomePage from './portfolio/HomePage';
import PhotoAddPage from './admin/PhotoAddPage';
import PhotoEditPage from './admin/PhotoEditPage';
import PhotosPage from './portfolio/PhotosPage';
import PhotoPage from './portfolio/PhotoPage';
import ReactGA from 'react-ga';

ReactGA.initialize('G-0462RKQPQ9'); // Replace with your Google Analytics tracking ID

function App() {
  const error = useSelector(state => state.error.message);
  const progress = useSelector(state => !!state.operations.operationsCounter);
  const dispatch = useDispatch();

  return (
    <div className="App">
      <ErrorDialog open={!!error} message={error} onClose={() => dispatch(clearError())} />
      <Overlay isOpen={progress}>
        <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
      </Overlay>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} >
            <Route path="" element={<HomePage />} />
            <Route path="galleries" element={<GalleriesPage />} />
            <Route path="galleries/:categoryId" element={<GalleriesByCategoryPage />} />
            <Route path="galleries/photos/:galleryId" element={<PhotosPage />} />
            <Route path="galleries/photo/:photoId" element={<PhotoPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contacts" element={<ContactsPage />} />
          </Route>
          <Route path="/admin" element={<AdminPage />}>
            <Route path="" element={<Navigate to="categories" />} />
            <Route path="categories" element={<CategoriesListPage />} />
            <Route path="categories/add" element={<CategoryAddPage />} />
            <Route path="categories/edit/:categoryId" element={<CategoryEditPage />} />
            <Route path="galleries" element={<GalleriesListPage />} />
            <Route path="galleries/add" element={<GalleryAddPage />} />
            <Route path="galleries/edit/:galleryId" element={<GalleryEditPage />} />
            <Route path="photos" element={<PhotosListPage />} />
            <Route path="photos/add" element={<PhotoAddPage />} />
            <Route path="photos/edit/:photoId" element={<PhotoEditPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
