import React, { useCallback, useState } from 'react';
import { TextField, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { selectedImageStyle, noImageDivStyle, VisuallyHiddenInput, formStyle } from './styles';


const CategoryEditForm = ({ handleSubmit, category, handleCategoryChange, coverSrc, handleCoverImageChange }) => {
    const [changed, setChanged] = useState(false);

    const handleCategoryNameChange = useCallback((e) => {
        handleCategoryChange({ ...category, categoryName: e.target.value });
        setChanged(true);
    }, [category, handleCategoryChange]);

    const handleCoverImageChangeInternal = useCallback((e) => {
        const file = e.target.files[0]
        if (!file) {
            return;
        }

        const fileURL = URL.createObjectURL(file);
        handleCoverImageChange({ file, fileURL });
        setChanged(true);
    }, [handleCoverImageChange]);

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <TextField
                label="Category Name"
                value={category?.categoryName || ""}
                margin="normal"
                onChange={handleCategoryNameChange}
                sx={{ minWidth: 300 }}
            />
            {!!coverSrc ? <img src={coverSrc} style={selectedImageStyle} alt='Category Cover' /> : <div style={noImageDivStyle}>Choose cover image</div>}
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} margin="dense">
                Cover Image
                <VisuallyHiddenInput type="file" onChange={handleCoverImageChangeInternal} />
            </Button>
            <Button type="submit" variant="contained" onClick={handleSubmit} disabled={!changed || !coverSrc || !category?.categoryName} style={{ alignSelf: 'center' }}>Save</Button>
        </form>
    );
};

export default CategoryEditForm;