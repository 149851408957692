import { firebaseApp } from '../firebase';
import { getAuth, signInWithEmailAndPassword  } from 'firebase/auth';


// Action types
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

// Login action
export const loginStart = () => {
    return { type: LOGIN_START };
};

export const loginSuccess = (user) => {
    return { type: LOGIN_SUCCESS, payload: user };
};

export const loginError = (errorMessage) => {
    return { type: LOGIN_ERROR, payload: errorMessage };
};

export const login = (email, password) => {
    return async (dispatch) => {
        try {
            dispatch(loginStart());
            await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
            dispatch(loginSuccess(getAuth(firebaseApp).currentUser));
        } catch (error) {
            dispatch(loginError(error.message));
        }
    };
};

export const logoutStart = () => {
    return { type: LOGOUT_START };
};

export const logoutSuccess = () => {
    return { type: LOGOUT_SUCCESS };
};

export const logoutError = (errorMessage) => {
    return { type: LOGOUT_ERROR, payload: errorMessage };
};

export const logout = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutStart());
            await getAuth(firebaseApp).signOut();
            dispatch(logoutSuccess());
        } catch (error) {
            dispatch(logoutError(error.message));
        }
    };
};
