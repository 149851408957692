import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, updateCategory } from '../actions/categoryActions';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryEditForm from './CategoryEditForm';
import Paper from '@mui/material/Paper';
import { adminPageRootStyle } from './styles';

const CategoryEditPage = () => {
    const [category, setCategory] = useState(null);
    const [coverSrc, setCoverSrc] = useState('');
    const [coverImage, setCoverImage] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryId } = useParams();

    const categoryRedux = useSelector(state => state.category.category);

    useEffect(() => {
        dispatch(getCategory(categoryId));
    }, [categoryId, dispatch]);

    useEffect(() => {
        if (categoryRedux) {
            setCategory({ ...categoryRedux });
            setCoverSrc(categoryRedux.coverURL);
        }
    }, [categoryRedux]);

    const handleCategoryChange = useCallback(c => {
        setCategory(c);
    }, []);

    const handleCoverImageChange = useCallback(({ file, fileURL }) => {
        setCoverSrc(fileURL);
        setCoverImage(file);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(updateCategory(categoryId, category, coverImage, navigate));
    }, [categoryId, category, coverImage, navigate, dispatch]);

    return (
        <Paper style={adminPageRootStyle}>
            <h2>Edit Category</h2>
            <CategoryEditForm
                handleSubmit={handleSubmit}
                category={category}
                handleCategoryChange={handleCategoryChange}
                coverSrc={coverSrc}
                handleCoverImageChange={handleCoverImageChange}
            />
        </Paper>
    );
};

export default CategoryEditPage;
