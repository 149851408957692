import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCWIfnJFVWqKDrDbku5QHvHKueHxl0-5QA",
    authDomain: "photo-portfolio-ln.firebaseapp.com",
    projectId: "photo-portfolio-ln",
    storageBucket: "photo-portfolio-ln.appspot.com",
    messagingSenderId: "135331137482",
    appId: "1:135331137482:web:9eb1b2950c238b50e6b133",
    measurementId: "G-0462RKQPQ9"
  };
  
  // Initialize Firebase
  export const firebaseApp = initializeApp(firebaseConfig);