import './HomePage.css';
import 'react-slideshow-image/dist/styles.css'
import React, { Fragment, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { loadSlideshow } from '../actions/photoActions';
import { Fade } from 'react-slideshow-image';

const HomePage = () => {
    const dispatch = useDispatch();
    const urls = useSelector(state => state.photo.slideshowUrls);

    useEffect(() => {
        dispatch(loadSlideshow());
    }, [dispatch]);

    return (
        <Paper className='home-page'>
            <Fade className='slide' prevArrow={<Fragment/>} nextArrow={<Fragment/>} canSwipe={false} duration={2000} transitionDuration={750}>
                {(urls ?? []).map((url) => <div className="each-slide-effect" key={url}>
                    <div style={{ 'backgroundImage': `url(${url})` }}>
                    </div>
                </div>)}
            </Fade>
            <div className='welcome-text'>Welcome to the visual journey of ValeTer (Valerii Tereshchenko), a passionate landscape photographer based in Pacific Northwest of the USA.</div>
        </Paper>
    );
};

export default HomePage;
