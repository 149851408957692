import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadGalleries } from '../actions/galleryActions';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { adminPageRootStyle } from './styles';
import { createPhoto } from '../actions/photoActions';
import PhotoEditForm from './PhotoEditForm';

const PhotoAddPage = () => {
    const [photo, setPhoto] = useState(null);
    const [image, setImage] = useState('');
    const [photoSrc, setPhotoSrc] = useState('');

    const galleries = useSelector(state => state.gallery.galleries);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(loadGalleries());
    }, [dispatch]);

    const handlePhotoChange = useCallback((p) => {
        setPhoto(p);
    }, []);

    const handleImageChange = useCallback(({ file, fileURL }) => {
        setPhotoSrc(fileURL);
        setImage(file);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(createPhoto(photo, image, navigate));
    }, [photo, image, navigate, dispatch]);

    return (
        <Paper style={adminPageRootStyle}>
            <h2>Add Photo</h2>
            <PhotoEditForm
                handleSubmit={handleSubmit}
                photo={photo}
                handlePhotoChange={handlePhotoChange}
                photoSrc={photoSrc}
                handleImageChange={handleImageChange}
                galleries={galleries}
            />
        </Paper>
    );
};

export default PhotoAddPage;
