import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { combineReducers } from 'redux';
import { counter } from '../reducers/counterReducer';
import { auth } from '../reducers/authReducer';
import { category } from '../reducers/categoryReducer';
import { error } from '../reducers/errorReducer';
import { operations } from '../reducers/operationsReducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { gallery } from '../reducers/galleryReducer';
import { photo } from '../reducers/photoReducer';

const rootReducer = combineReducers({
  counter,
  error,
  operations,
  auth,
  category,
  gallery,
  photo,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk/*, gaMiddleware*/)
  )
);

export default store;
